import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import BannerForm from '../../core/sysext/Website/containers/Banner/components/Form';
import BannerList from '../../core/sysext/Website/containers/Banner/components/List';

const BannersPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? (
        <BannerForm docId={star} {...props} />
      ) : (
        <BannerList {...props} />
      )}
    </Backoffice>
  );
};

export default BannersPage;
